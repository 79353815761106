import CardData from "../../componentes/CardData/CardData";
import WhatsBoton from "../../componentes/WhatsBoton/WhatsBoton";
import "./tratamientos.css";

const Tratamientos = () => {
  return (
    <div className="tratamientosMain">
      <section className="contenedor-tratamientos">
        <CardData/>
      </section>
        <WhatsBoton/>
    </div>
  );
};

export default Tratamientos;
