import { useState } from "react";
import { motion } from "framer-motion";
import "./card.css";

const Card = (props) => {
  const [isExpaned, setIsExpaned] = useState(false);
  const animate = {
    layout: "position",
    animate: { opacity: 1 },
    transition: { delay: 0.3 },
    initial: { opacity: 0 },
  };

  return (    
    <motion.div
      className="card"
      layout
    >
      <motion.h2 layout="position" className="card-titulo">{props.titulo}</motion.h2>        
        <motion.p layout="position" className="card-texto">
          {props.encabezado}
          <br />
        </motion.p>
        {isExpaned && (
          <>
            <motion.p {...animate} className="card-texto">
              {props.incluye}
            </motion.p>
          </>
        )}
        <motion.div {...animate} className="btn-container">
          <button
            onClick={() => {
              setIsExpaned(!isExpaned);
            }}
          >
            Ver Mas
          </button>
        </motion.div>        
    </motion.div>    
  );
};

export default Card;
