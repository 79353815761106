import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./componentes/Navbar/Navbar";
import Home from "./routes/Home/Home";
import Cursos from "./routes/Cursos/Cursos"
import CartWidget from "./componentes/CartWidget/CartWidget";
import Tratamientos from "./routes/Tratamientos/Tratamientos";
import Contacto from "./routes/Contacto/Contacto";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path="/tratamientos" element={<Tratamientos />} />
        <Route exact path="/cursos" element={<Cursos />} />
        <Route exact path="/contacto" element={<Contacto />} />
        <Route exact path="/cart" element={<CartWidget />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
