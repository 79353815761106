import video from "../../Imagenes/video.mp4"
import Logo4 from "../../Imagenes/Logo4.png";
import "./home.css";

const Home = () => {
  return (
    <>
    <div className="contenedorMain">
      <video autoPlay muted loop playsInline className="video">
        <source src={video} type="video/mp4"/>
      </video>
      <img src={Logo4} alt="" className="imagenHome" />
      <p className="parrafoHome">COSMIATRA</p>
    </div>    
    </>
  );
};

export default Home;
