import Logo from "../../Imagenes/Logo.jpg";
import InstaBoton from "../../componentes/InstaBoton/InstaBoton";
import WhatsBoton from "../../componentes/WhatsBoton/WhatsBoton";
import "./cursos.css"

const Cursos = () => {
  return (
    <>
    <div className="contenedorCursos">
      <div className="bodyCursos">
        <img src={Logo} className="imagen-curso" alt="logo" />
        <h2 className="texto-cursos">¡¡Estoy Preparando el Curso que Vos Necesitas!!</h2>
      </div>
    </div>
    <InstaBoton/>
    <WhatsBoton/>
    </>
  );
};

export default Cursos;
