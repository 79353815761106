import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Burger from "../Burger/Burger";
import "./navbar.css";

const Navbar = () => {
  const [clicked, setClicked] = useState(false);
  const ubicacion = useLocation();

  const navbarClass = ubicacion.pathname === "/" ? "navbar-home" : "navbar-pages";

  const handleClick = () => {
    setClicked(!clicked)
  }

  return (
    <div className={`nav-container ${navbarClass}`}>
      <nav className= {` navbar responsivo ${clicked ? 'active' : ''} ${navbarClass}`}>
        <Link to="/" className="link-logo" onClick={handleClick}>
          <h1 className="navbar-logo">Lole Sier</h1>
        </Link>
        <Link to="/tratamientos" className="opcion" onClick={handleClick}>
          Tratamientos
        </Link>
        <Link to="/cursos" className="opcion" onClick={handleClick}>
          Cursos
        </Link>
        <Link to="/contacto" className="opcion" onClick={handleClick}>
          Contacto
        </Link>
        <Link to="/cart" className="cart" onClick={handleClick}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="cart" viewBox="0 0 16 16">
            <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2" />
          </svg>
        </Link>
      </nav>
        <div className="burger">
          <Burger clicked={clicked} handleClick={handleClick}/>
        </div>
        <div className={`initial ${clicked ? 'active' : ''}`}></div>
    </div>
  );
};

export default Navbar;
