import { tratamientos } from "../../data/tratamientos.js";
import Card from "../Card/Card";
import "./carData.css";

const CardData = () => {
  return (
    <div className="tratamientos-card">
      {tratamientos.map((tratamiento) => {
        return <Card key={tratamiento.id} titulo={tratamiento.titulo} encabezado={tratamiento.encabezado} incluye={tratamiento.incluye}/>
      })}
    </div>
  )
}

export default CardData